import React from "react";
import { Layout, RichText} from "components";
import { graphql } from "gatsby";

export default function ContentfulPage(props) {
    //console.log(props);

    return (
        <Layout>
            {!!props.data.contentfulPage.pageContent}
            <RichText
                references={props.data.contentfulPage.pageContent.references}
                raw={props.data.contentfulPage.pageContent.raw}
            />
        </Layout>
    )
}

export const query = graphql`
    query PageQuery($id: String) {
        contentfulPage(id: {eq: $id}) {
            id
            title
            pageContent {
                raw
                references{
                    ...on ContentfulHero{
                        __typename
                        contentful_id
                        heading,
                        preHeading,
                        backgroundImage {
                            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                        },
                        subHeadingText {
                            subHeadingText
                        }
                    }
                    ... on ContentfulFeatureProduct {
                        __typename
                        contentful_id
                        title
                        subtitle
                        text {
                            text
                        }
                        video {
                            file {
                                url
                            }
                        }
                    }
                    ... on ContentfulServicesGroup {
                        __typename
                        contentful_id
                        title
                        subtitle
                        serviceOptions {
                            id
                            title
                            anchor
                            text {
                                raw
                            }
                            icon {
                                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                            }
                        }
                    }
                    ... on ContentfulService {
                        __typename
                        contentful_id
                        title
                        subtitle
                        aternate
                        productImage {
                            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                        }
                        bigPill {
                            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                        }
                        floatPill {
                            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                        }
                        atributeList {
                            atributes {
                                contentful_id
                                title
                                icon {
                                    id
                                    file {
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;